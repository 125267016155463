import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import QRCodeSlider from "../../Core/Components/QRCodeSlider/QRCodeSlider";
import { RFPROFILESETUP } from "../../../constants/applicationConstants";
import { DataTableHoc, Loader, ErrorFallback } from "smart-react";
import { GridColumnsList } from "../Components/Filter/FilterElements";
import DataColumns from "../Components/DataColumns/DataColumns";
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from "../../../constants/applicationConstants";
import {
  deleteFilter,
  applyFilter,
  saveFilters,
} from "../../../Utils/Filters/filterSliderHandler";
import ConfigurationContentSlider from "../Components/ContentSlider/ConfigurationContentSlider";
import "./RFProfileSetup.scss";
const BaseProfileSetupDataTable = React.lazy(
  () => import("../Components/DataTable/DataTable")
);
const ProfileSetupDataTable = DataTableHoc(BaseProfileSetupDataTable);

/**
 * RFPROFILESETUP main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The RFPROFILESETUP component.
 */
const RFProfileSetup = ({ profile }) => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  const [editItem, setEditItem] = React.useState(null);
  const [singleProfile, setSingleProfile] = React.useState("");
  const [showQRModal, setShowQRModal] = React.useState(false);
  const [isReloadData, setIsReloadData] = React.useState(false);
  /**
   * handle Generate QRCode
   * @param profile
   */
  const handleGenerateQRCode = (profile) => {
    setShowQRModal(true);
    setSingleProfile(profile);
  };

  /**
   * show edit content slider
   * @param {*} dataItem
   */
  const editContentSlider = (dataItem) => {
    setEditItem(dataItem);
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto k-px-4">
        <React.Suspense fallback={<Loader />}>
          <ProfileSetupDataTable
            profile={profile}
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={RFPROFILESETUP}
            dataColumns={dataColumnsArray}
            gridColumnsList={() =>
              GridColumnsList({ editContentSlider, handleGenerateQRCode })
            }
            dataTableName={RFPROFILESETUP}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${RFPROFILESETUP}.DataGrid.RowLayout`}
            pageLengthConfigName={`${RFPROFILESETUP}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: "id",
              dir: "desc",
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
            editItem={editItem}
            isReloadData={isReloadData}
          />
        </React.Suspense>
        <ConfigurationContentSlider show={showQRModal}>
          <QRCodeSlider
            showQRModal={showQRModal}
            singleProfile={singleProfile}
            setSingleProfile={setSingleProfile}
            setShowQRModal={setShowQRModal}
            isReloadData={isReloadData}
            setIsReloadData={setIsReloadData}
          />
        </ConfigurationContentSlider>
      </div>
    </ErrorBoundary>
  );
};
export default RFProfileSetup;
